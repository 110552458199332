import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { isAuthenticated } from '../../lib/auth'
import { getMyProfile } from '../../lib/api'

class SecondHandNavbar extends React.Component {
  state = {
    mainButton: "",
    text: '',
    slogan: 1,
    isPhone: false
  }

  async componentDidMount() {
    const number = Math.round(Math.random() * 10)
    const phone = window.matchMedia("(pointer: coarse)").matches

    if (number > 5) {
      this.setState({slogan: 2, isPhone: phone})
    } else {
      this.setState({slogan: 1, isPhone: phone})
    }
  }

  revealText = (option) => {
    this.setState({ text: option })
  }

  changeMainButton = (hoveredItem) => {
    if (!window.matchMedia("(pointer: coarse)").matches) {
      this.setState({ mainButton: hoveredItem })
    }
  }

  mainButtonBack = () => {
    this.setState({ mainButton: "" })
  }

  render() {
    return (
      <div className="second-hand-navbar">

        {(!this.state.isPhone && window.innerWidth >= 600) &&
          <a href="/" className="link-no-underline" alt="Home Page">
            
            <div className="menu" onMouseLeave={() => {
              this.revealText("")
            }}>

              <div className="logo label"></div>

              <div className="spacer"></div>

              <Link to="/products" className="item one" onMouseEnter={() => {
                this.revealText("Our Shop")
              }} onMouseLeave={() => {
                this.revealText("")
              }}>
                {/* <div className='item-text'>Our Shop</div> */}
              </Link>

              <Link to="/second-hand" className="item two" onMouseEnter={() => {
                this.revealText("Second Hand Market")
              }} onMouseLeave={() => {
                this.revealText("")
              }}>
                {/* <div className='item-text'>Second Hand Market</div> */}
              </Link>

              <Link to="/forum" className="item three" onMouseEnter={() => {
                this.revealText("Forum")
              }} onMouseLeave={() => {
                this.revealText("")
              }}>
                {/* <div className='item-text'>Forum</div> */}
              </Link>
              
            </div>
          </a>
        }

        {(this.state.isPhone || window.innerWidth < 600) &&                  
            <div className="menu" onMouseLeave={() => {
              this.revealText("")
            }}>

              <div className="logo label"></div>

              <div className="spacer"></div>

              <Link to="/products" className="item one" onMouseEnter={() => {
                this.revealText("Our Shop")
              }} onMouseLeave={() => {
                this.revealText("")
              }}></Link>
              
              <Link to="/second-hand" className="item two" onMouseEnter={() => {
                this.revealText("Second Hand Market")
              }} onMouseLeave={() => {
                this.revealText("")
              }}></Link>

              <Link to="/forum" className="item three" onMouseEnter={() => {
                this.revealText("Forum")
              }} onMouseLeave={() => {
                this.revealText("")
              }}></Link>

              <Link to="/" className="item four" onMouseEnter={() => {
                this.revealText("Homepage")
              }} onMouseLeave={() => {
                this.revealText("")
              }}></Link>
                    
            </div>
        }

        {this.state.slogan === 1 &&
          <h1 className="slogan-header">Bring Hippies <br /> Back</h1>
        }

        {this.state.slogan === 2 &&
          <h1 className="slogan-header">Make Fashion <br /> Slow Again</h1>
        }
        
      </div>
    )
  }
}
export default SecondHandNavbar